import { FC, lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper';
import { auth } from '../services/FirebaseClient';

import { useAppDispatch } from '../hooks/useRedux';
import { setUser, setUserAccess } from '../store/auth.reducer';

import { useAppSelector } from '../hooks/useRedux';

const PrivateRoutes = () => {
  const { menu } = useAppSelector((state) => state.auth);

  const listFormDetail = [
    {
      id: 'customer',
      route: 'detail/customer/*',
    },
    {
      id: 'customer-order',
      route: 'detail/customer/create-order/*',
    },
    {
      id: 'reseller',
      route: 'detail/reseller/*',
    },
    {
      id: 'reseller-order',
      route: 'detail/reseller/create-order/*',
    },
    {
      id: 'order-detail',
      route: 'detail/order/*',
    },
    {
      id: 'promotions',
      route: 'form/promotions/*',
    },
    {
      id: 'coupons',
      route: 'form/coupon/*',
    },
    {
      id: 'product-price',
      route: 'form/product-price/*',
    },
    {
      id: 'history',
      route: 'detail/history/*',
    },
    {
      id: 'blog-form',
      route: 'form/blog/*',
    },
  ];

  // mapping page
  const UserPage = lazy(() => import('../modules/apps/user'));
  const RolePage = lazy(() => import('../modules/apps/role'));
  const ProductPage = lazy(() => import('../modules/apps/product'));
  const ProductTypePage = lazy(() => import('../modules/apps/product-type'));
  const ProductEventPricePage = lazy(() => import('../modules/apps/product-event-price'));
  const ProductCategoryPage = lazy(() => import('../modules/apps/product-category'));
  const ProductSubCategoryPage = lazy(() => import('../modules/apps/product-sub-category'));
  const BannerPage = lazy(() => import('../modules/apps/banner'));
  const BannerProductPage = lazy(() => import('../modules/apps/banner-productpage'));
  const CouponPage = lazy(() => import('../modules/apps/coupon'));
  const CouponFormPage = lazy(() => import('../modules/apps/coupon/form'));
  const SocmedPage = lazy(() => import('../modules/apps/social-media'));
  const CustomerPage = lazy(() => import('../modules/apps/customer'));
  const OrderPage = lazy(() => import('../modules/apps/order'));
  const ReportPage = lazy(() => import('../modules/apps/report'));
  // const HighlightPage = lazy(() => import('../modules/apps/highlight'));
  const ChangePasswordPage = lazy(() => import('../modules/apps/change-password'));
  const CustomerDetail = lazy(() => import('../modules/apps/customer/detail'));
  const ProductEventPriceForm = lazy(() => import('../modules/apps/product-event-price/form'));
  const CustomerOrder = lazy(() => import('../modules/apps/customer/order'));
  const LabelPage = lazy(() => import('../modules/apps/label'));
  const OrderDetail = lazy(() => import('../modules/apps/order/detail'));
  const ProductPricePage = lazy(() => import('../modules/apps/product-price'));
  const ProductPriceFormPage = lazy(() => import('../modules/apps/product-price/form'));
  const ManipulateProduct = lazy(() => import('../modules/apps/product/manipulate'));
  const CustomerFeedback = lazy(() => import('../modules/apps/customer-feedback'));
  const UniqueKey = lazy(() => import('../modules/apps/customer-feedback/unique-key'));
  const AppSettingPage = lazy(() => import('../modules/apps/app-setting'));
  const ResellerPage = lazy(() => import('../modules/apps/reseller'));
  const BannerPopUpPage = lazy(() => import('../modules/apps/banner-popup'));
  const HistoryPage = lazy(() => import('../modules/apps/history'));
  const ProductIngredientPage = lazy(() => import('../modules/apps/product-ingredient'));
  const NotableEffectPage = lazy(() => import('../modules/apps/notable-effect'));
  const SkinTypePage = lazy(() => import('../modules/apps/skin-type'));
  const SkinConcernTypePage = lazy(() => import('../modules/apps/skin-concern-type'));
  const SkinConcernCategoryPage = lazy(() => import('../modules/apps/skin-concern-category'));
  const SkinConcernSubCategoryPage = lazy(
    () => import('../modules/apps/skin-concern-sub-category'),
  );
  const AdsRunningTextPage = lazy(() => import('../modules/apps/ads-running-text'));
  const SahabatScarlettPage = lazy(() => import('../modules/apps/sahabat-scarlett'));
  const BlogCreate = lazy(() => import('../modules/apps/blog/create'));
  const BlogList = lazy(() => import('../modules/apps/blog/list'));

  const dispatch = useAppDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const detailUser = {
          email: user.email || '',
          displayName: user.displayName || '',
        };

        dispatch(
          setUser({
            user: detailUser,
          }),
        );

        const tempMenu = localStorage.getItem('access');
        const role = localStorage.getItem('role');
        if (tempMenu) {
          dispatch(
            setUserAccess({
              access: {
                accessRights: JSON.parse(tempMenu),
                roleName: role || '',
                name: '',
                userType: '',
              },
            }),
          );
        }
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='change-password'
          element={
            <SuspensedView>
              <ChangePasswordPage />
            </SuspensedView>
          }
        />

        {menu.map((val: any, idx: number) => {
          if (val.hasAccess) {
            return (
              <Route
                key={idx}
                path={`${val?.to}/*`}
                element={
                  <SuspensedView>
                    {val.id === 'user' ? <UserPage /> : null}
                    {val.id === 'role' ? <RolePage /> : null}
                    {val.id === 'product' ? <ProductPage /> : null}
                    {val.id === 'product-type' ? <ProductTypePage /> : null}
                    {val.id === 'product-category' ? <ProductCategoryPage /> : null}
                    {val.id === 'product-sub-category' ? <ProductSubCategoryPage /> : null}
                    {val.id === 'promotions' ? <ProductEventPricePage /> : null}
                    {val.id === 'banner' ? <BannerPage /> : null}
                    {val.id === 'banner-productpage' ? <BannerProductPage /> : null}
                    {val.id === 'customer' ? <CustomerPage /> : null}
                    {val.id === 'customer-detail' ? <CustomerDetail /> : null}
                    {val.id === 'order' ? <OrderPage /> : null}
                    {val.id === 'report' ? <ReportPage /> : null}
                    {/* {val.id === 'highlight' ? <HighlightPage /> : null} */}
                    {val.id === 'coupon' ? <CouponPage /> : null}
                    {val.id === 'social-media' ? <SocmedPage /> : null}
                    {val.id === 'label' ? <LabelPage /> : null}
                    {val.id === 'product-price' ? <ProductPricePage /> : null}
                    {val.id === 'create-product' ? <ManipulateProduct /> : null}
                    {val.id === 'detail-product' ? <ManipulateProduct /> : null}
                    {val.id === 'customer-feedback' ? <CustomerFeedback /> : null}
                    {val.id === 'unique-key' ? <UniqueKey /> : null}
                    {val.id === 'app-setting' ? <AppSettingPage /> : null}
                    {val.id === 'reseller' ? <ResellerPage /> : null}
                    {val.id === 'banner-popup' ? <BannerPopUpPage /> : null}
                    {val.id === 'product-ingredient' ? <ProductIngredientPage /> : null}
                    {val.id === 'notable-effect' ? <NotableEffectPage /> : null}
                    {val.id === 'skin-type' ? <SkinTypePage /> : null}
                    {val.id === 'ads-running-text' ? <AdsRunningTextPage /> : null}
                    {val.id === 'sahabat-scarlett' ? <SahabatScarlettPage /> : null}
                    {val.id === 'blog-list' ? <BlogList /> : null}
                    {val.id === 'skin-concern-type' ? <SkinConcernTypePage /> : null}
                    {val.id === 'skin-concern-category' ? <SkinConcernCategoryPage /> : null}
                    {val.id === 'skin-concern-sub-category' ? <SkinConcernSubCategoryPage /> : null}
                  </SuspensedView>
                }
              />
            );
          }
          return null;
        })}

        {listFormDetail.map((val: any, idx: number) => (
          <Route
            key={idx}
            path={val.route}
            element={
              <SuspensedView>
                {val.id === 'customer' && <CustomerDetail />}
                {val.id === 'customer-order' && <CustomerOrder />}
                {val.id === 'reseller' && <CustomerDetail />}
                {val.id === 'reseller-order' && <CustomerOrder />}
                {val.id === 'promotions' && <ProductEventPriceForm />}
                {val.id === 'coupons' && <CouponFormPage />}
                {val.id === 'order-detail' && <OrderDetail />}
                {val.id === 'product-price' && <ProductPriceFormPage />}
                {val.id === 'history' && <HistoryPage isTitle />}
                {val.id === 'blog-form' ? <BlogCreate /> : null}
              </SuspensedView>
            }
          />
        ))}
        {/* <Route
          path='detail/customer/*'
          element={
            <SuspensedView>
              <CustomerDetail />
            </SuspensedView>
          }
        /> */}
        {/* Page Callback */}
        <Route path='callback/*' element={<Navigate to='/callback' />} />

        {/* Page Not Permitted */}
        <Route path='error/403' element={<Navigate to='/error/403' />} />
        {/* Page Not Found */}
        <Route path='error/404' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
