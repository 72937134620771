const API = {
  user: {
    users: 'admin/users',
    access: 'admin/users/access-rights',
    changePassword: 'users/change-password',
    reseller: 'admin/users/reseller',
  },
  role: 'admin/roles',
  product: 'admin/products',
  productType: 'admin/product-types',
  productTypeList: 'product-types',
  productCategory: 'admin/product-categories',
  productSubCategory: 'admin/product-sub-categories',
  productEventPrice: 'admin/events',
  customer: 'admin/users',
  address: 'admin/user-addresses',
  lookup: {
    country: 'lookup/country',
    province: 'lookup/province',
    city: 'lookup/city',
    district: 'lookup/district',
    subDistrict: 'lookup/subDistrict',
    customerPriceType: 'lookup/customer-price-type',
  },
  highlight: 'admin/highlights',
  upload: 'admin/file-content',
  banner: 'admin/banners',
  bannerProductPage: 'admin/banner-all-shops',
  bannerPopUp: 'admin/popup-banners',
  order: 'admin/orders',
  orderTracking: 'orders/tracking',
  coupon: 'admin/coupons',
  socmed: {
    tiktok: 'admin/social-media-integration/tiktok',
  },
  shipping: {
    method: 'admin/shippings/method',
    cost: 'admin/shippings/cost',
  },
  cart: 'admin/carts',
  payment: 'admin/payments/method',
  label: 'admin/labels',
  validate: 'orders/payment/validate',
  comments: 'admin/comments',
  dashboard: {
    order: 'admin/dashboards/order',
    product: 'admin/dashboards/product',
    courier: 'admin/dashboards/courier',
    payment: 'admin/dashboards/payment-method',
  },
  shipment: {
    shipments: 'admin/shipments',
    processed: 'admin/shipments/processed',
    instant: 'admin/shipments/instant',
  },
  productPricing: 'admin/product-prices',
  productPrice: 'admin/product-price-options',
  promotions: 'admin/promotions',
  customerFeedback: 'admin/customer-feedbacks',
  downloadTemplateCoupon: 'admin/coupons/download-template',
  downloadBulkCoupon: 'admin/coupons/download-coupon-code',
  uploadCouponByEmail: 'admin/coupons/upload-email',
  downloadCouponWhitelist: 'admin/coupons/download-whitelist',
  appSetting: 'admin/appsettings',
  optionPrice: 'admin/product-price-options',
  invoice: 'admin/invoices',
  accessRights: 'admin/access-rights',
  bestSeller: 'admin/dashboards/product/best-seller',
  productIngredient: 'admin/ingredients',
  notableEffect: 'admin/notable-effects',
  skinType: 'admin/skin-types',
  skinConcernType: 'admin/skin-concern-types',
  skinConcernCategory: 'admin/skin-concern-categories',
  skinConcernSubCategory: 'admin/skin-concern-sub-categories',
  runningAds: 'admin/running-ads',
  sahabatScarlett: 'admin/friends-reel',
  blog: 'admin/blogs',
};

export default API;
