import { configureStore } from '@reduxjs/toolkit';

import AppSettingReducer from './appSetting.reducer';
import authReducer from './auth.reducer';
import bannerReducer from './banner.reducer';
import BannerPopUpReducer from './bannerPopUp.reducer';
import BlogScarlettReducer from './blog.reducer';
import couponReducer from './coupon.reducer';
import customerReducer from './customer.reducer';
import dashboardReducer from './dashboard.reducer';
import highlightReducer from './highlight.reducer';
import LabelReducer from './label.reducer';
import NotableEffectReducer from './notableEffect.reducer';
import orderReducer from './order.reducer';
import productReducer from './product.reducer';
import productCategoryReducer from './productCategory.reducer';
import productEventPriceReducer from './productEventPrice.reducer';
import ProductIngredientReducer from './productIngredient.reducer';
import ProductPriceReducer from './productPrice.reducer';
import ResellerReducer from './reseller.reducer';
import RunningAds from './runningAds.reducer';
import SahabatScarlettReducer from './sahabatScarlett.reducer';
import SkinTypeReducer from './skinType.reducer';
import socmedReducer from './socmed.reducer';
import userReducer from './user.reducer';
import sinConcernTypeReducer from './skinConcernType.reducer';
export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    productCategory: productCategoryReducer,
    user: userReducer,
    product: productReducer,
    customer: customerReducer,
    highlight: highlightReducer,
    banner: bannerReducer,
    order: orderReducer,
    coupon: couponReducer,
    socmed: socmedReducer,
    productEventPrice: productEventPriceReducer,
    label: LabelReducer,
    productPrice: ProductPriceReducer,
    appSetting: AppSettingReducer,
    reseller: ResellerReducer,
    bannerPopUp: BannerPopUpReducer,
    productIngredient: ProductIngredientReducer,
    notableEffect: NotableEffectReducer,
    skinType: SkinTypeReducer,
    runningAds: RunningAds,
    sahabatScarlett: SahabatScarlettReducer,
    blogScarlett: BlogScarlettReducer,
    skinConcernType: sinConcernTypeReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
